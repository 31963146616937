import { useTranslation } from 'react-i18next';
import { Typography, Container, Divider, Card } from '@mui/material';
import { MainContent } from '../style';

const StatusComingSoon = () => {
  const { t } = useTranslation();

  return (
    <>
      <MainContent>
        <Container className="coming_soon_container" maxWidth="sm">
          <Card>
            <img
              alt="Coming Soon"
              height={200}
              src="/static/images/status/coming-soon.svg"
            />
            <Typography variant="h2">Coming Soon</Typography>
            <Divider className="divider" />
            <Typography
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
              className="description"
            >
              {t(`새로운 기능을 위해 \n 작업 중인 페이지입니다`)}
            </Typography>
          </Card>
        </Container>
      </MainContent>
    </>
  );
};

export default StatusComingSoon;
