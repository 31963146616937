import { makeAutoObservable } from 'mobx';
import {
  getCompanyInfo,
  createNewCompany,
  inviteUser,
  updateCompanyInfo,
  updateCompanyPassword,
  generateResetCompanyPasswordEmail,
  updateCompanyPasswordByEmail,
  deleteCompany,
  updateCompanyUserRole,
  getCompanyUsers,
  deleteCompanyUser,
  getCompanyInviteLink,
  addEmployeeToCompany,
  postNewCompanyUserApi,
  postNewCompanyAndAdminApi,
} from 'src/api/company';

class CompanyStore {
  alertStore;

  companyInfo = {
    code: '',
    name: '',
    businessNumber: -1,
    contactEmail: '',
    contactNumber: -1,
    contactNumberCountryCode: 'KR',
    exContactNumber: -1,
    exContactNumberCountryCode: 'KR',
    inviteLink: '-',
    location: {
      id: '',
      name: '',
      type: '',
      address: '',
      addressDetail: '',
      countryCode: 'KR',
      zipCode: '',
    },
    homepageUrl: '',
  };

  userList = [];

  constructor({ alertStore }) {
    makeAutoObservable(this);
    this.alertStore = alertStore;
  }

  initializeCompany = () => {
    this.companyInfo = {
      code: '',
      name: '',
      businessNumber: -1,
      contactEmail: '',
      contactNumber: -1,
      contactNumberCountryCode: 'KR',
      exContactNumber: -1,
      exContactNumberCountryCode: 'KR',
      inviteLink: '-',
      location: {
        id: '',
        name: '',
        type: '',
        address: '',
        addressDetail: '',
        countryCode: 'KR',
        zipCode: '',
      },
      homepageUrl: '',
    };

    this.userList = null;
  };

  setUserList = (userList) => {
    this.userList = userList;
  };

  setCompanyInfo = (companyInfo) => {
    this.companyInfo = companyInfo;
  };

  getCompanyInfo = async () => {
    try {
      const res = await getCompanyInfo();
      this.setCompanyInfo(res);
      return true;
    } catch (error) {
      console.error('Error: ', error);
      this.setCompanyInfo(-1);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  getCompanyUserList = async () => {
    try {
      const res = await getCompanyUsers();
      this.setUserList(res);
      return true;
    } catch (error) {
      this.setCompanyInfo(-1);
      console.error('Error: ', error);
      throw error;
    }
  };

  setCompanyInviteCode = (inviteLink) => {
    this.companyInfo = {
      ...this.companyInfo,
      inviteLink: inviteLink,
    };
  };

  requestCompanyInviteLink = async () => {
    try {
      const res = await getCompanyInviteLink();
      this.setCompanyInviteCode(res);
      return true;
    } catch (error) {
      this.setCompanyInviteCode('-');
      console.error('Error: ', error);
      throw error;
    }
  };

  submitJoinCompany = async (companyId) => {
    try {
      const accessToken = await inviteUser(companyId);
      this.alertStore.setAlertOpen(
        'success',
        '그룹 가입처리가 정상적으로 수행되었습니다.',
      );

      return accessToken;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      throw error;
    }
  };

  submitJoinCompanyByCode = async (companyId) => {
    try {
      const accessToken = await addEmployeeToCompany(companyId);
      this.alertStore.setAlertOpen(
        'success',
        '그룹 가입처리가 정상적으로 수행되었습니다.',
      );

      return accessToken;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      throw error;
    }
  };

  submitCreateCompany = async (
    name,
    businessNumber,
    contactEmail,
    contactNumber,
    contactNumberCountry,
    exContactNumber,
    exContactNumberCountry,
    address,
    addressDetail,
    zipCode,
    lat,
    lon,
    companyHomepageUrl,
    password,
    countryCode,
  ) => {
    try {
      const accessToken = await createNewCompany(
        name,
        businessNumber,
        contactEmail,
        contactNumber,
        contactNumberCountry,
        exContactNumber,
        exContactNumberCountry,
        address,
        addressDetail,
        zipCode,
        lat,
        lon,
        companyHomepageUrl,
        password,
        countryCode,
      );
      this.alertStore.setAlertOpen(
        'success',
        '그룹가 정상적으로 생성되었습니다.',
      );

      return accessToken;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      throw error;
    }
  };

  changeCompanyProfiles = async (
    name,
    businessNumber,
    contactEmail,
    contactNumber,
    contactNumberCountry,
    exContactNumber,
    exContactNumberCountry,
    address,
    addressDetail,
    zipCode,
    lat,
    lon,
    countryCode,
    companyHomepageUrl,
    companypassword,
    newCompanyPassword,
  ) => {
    try {
      await this.changeCompanyInfos(
        name,
        businessNumber,
        contactEmail,
        contactNumber,
        contactNumberCountry,
        exContactNumber,
        exContactNumberCountry,
        address,
        addressDetail,
        zipCode,
        lat,
        lon,
        countryCode,
        companyHomepageUrl,
        companypassword,
      );

      if (newCompanyPassword) {
        await this.changeCompanyPassword(companypassword, newCompanyPassword);
      }
      this.getCompanyInfo();
      this.alertStore.setAlertOpen(
        'success',
        '그룹 정보 수정이 정상적으로 처리되었습니다.',
      );
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      throw error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  changeCompanyInfos = async (
    name,
    businessNumber,
    contactEmail,
    contactNumber,
    contactNumberCountry,
    exContactNumber,
    exContactNumberCountry,
    address,
    addressDetail,
    zipCode,
    lat,
    lon,
    countryCode,
    companyHomepageUrl,
    password,
  ) => {
    try {
      await updateCompanyInfo(
        name,
        businessNumber,
        contactEmail,
        contactNumber,
        contactNumberCountry,
        exContactNumber,
        exContactNumberCountry,
        address,
        addressDetail,
        zipCode,
        lat,
        lon,
        countryCode,
        companyHomepageUrl,
        password,
      );

      return true;
    } catch (error) {
      console.error('Error: ', error);
      throw error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  changeCompanyPassword = async (companypassword, newCompanyPassword) => {
    try {
      await updateCompanyPassword(companypassword, newCompanyPassword);
      this.alertStore.setAlertOpen(
        'success',
        '제어 비밀번호[CU Key] 수정이 정상적으로 처리되었습니다.',
      );
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      throw error;
    }
  };

  sendCompanyPasswordResetEmail = async (language) => {
    try {
      await generateResetCompanyPasswordEmail(language);
      // 알림 모달창 출력
      this.alertStore.setAlertOpen(
        'success',
        '비밀번호 초기화를 위한 인증 코드가 이메일로 발송되었습니다.\n받으신 인증 코드를 입력하여 비밀번호를 재설정해 주세요.',
      );

      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      throw error;
    }
  };

  resetCompanyPassword = async (password, verificationCode) => {
    // eslint-disable-next-line no-useless-catch
    try {
      await updateCompanyPasswordByEmail(password, verificationCode);
      this.alertStore.setAlertOpen(
        'success',
        '비밀번호가 정상적으로 수정되었습니다.',
        { linkTo: '/' },
      );

      return true;
    } catch (error) {
      throw error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  removeCompany = async (userPassword, companyPassword) => {
    try {
      const accessToken = await deleteCompany(userPassword, companyPassword);
      this.alertStore.setAlertOpen(
        'success',
        '그룹 삭제가 정상적으로 처리되었습니다.',
      );
      return accessToken;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      throw error;
    }
  };

  leaveCompany = async (userId, password) => {
    try {
      const accessToken = await deleteCompanyUser(userId, password);

      this.alertStore.setAlertOpen(
        'success',
        `그룹 탈퇴가 정상적으로 처리되었습니다.`,
      );

      return accessToken;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  deleteUsersFromCompany = async (selectedUsers) => {
    try {
      const deleteUserTasks = selectedUsers.map((userInfo) => {
        return deleteCompanyUser(userInfo.id);
      });

      await Promise.all(deleteUserTasks);

      const updatedUsers = this.userList.filter(
        (user) => !selectedUsers.includes(user),
      );
      this.setUserList(updatedUsers);
      this.alertStore.setAlertOpen(
        'success',
        `유저 삭제가 정상적으로 처리되었습니다.`,
      );

      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  // eslint-disable-next-line class-methods-use-this
  changeUserPermission = async (userId, role) => {
    try {
      await updateCompanyUserRole(userId, role);
      this.alertStore.setAlertOpen(
        'success',
        '사용자 권한 수정이 완료되었습니다.',
      );
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      throw error;
    }
  };

  createNewCompanyUser = async (
    username,
    email,
    phone,
    countryCode,
    timeZone,
    newPassword,
    confirmPassword,
  ) => {
    try {
      const res = await postNewCompanyUserApi(
        username,
        email,
        phone,
        countryCode,
        timeZone,
        newPassword,
        confirmPassword,
      );
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '새로운 관리자 계정 추가가 완료되었습니다.',
      );
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  createNewCompanyAndAdmin = async (group, user, password) => {
    try {
      const res = await postNewCompanyAndAdminApi(group, user, password);
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '새로운 관리자 계정 추가가 완료되었습니다.',
      );
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };
}

export default CompanyStore;
