export const ParameterInfo = {
  sensors: {},
  managements: {},
  control: {},
  kpi: {},
};

export default ParameterInfo;

export const FirebaseConfig = ['sensors', 'managements', 'control', 'kpi'];
