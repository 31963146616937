import { makeAutoObservable } from 'mobx';
import { deleteImageApi } from 'src/api/image';
import {
  deleteItemApi,
  deleteItemCountryApi,
  deleteItemTypeApi,
  deleteItemUnitApi,
  getAllItemsApi,
  getContactApi,
  getItemCountryApi,
  getItemInforApi,
  getItemTypeApi,
  getItemUnitApi,
  getAllReceivingPlanningApi,
  patchItemApi,
  postItemApi,
  postItemCountryApi,
  postItemTypeApi,
  postItemUnitApi,
  postReceivingPlanningApi,
  getReceivingPlanningInforApi,
  patchReceivingPlanningApi,
  deleteReceivingPlanningApi,
  completeReceivingPlanningApi,
  cancelReceivingPlanningApi,
  getAllReceivingHistoryApi,
  getReceivingHistoryInforApi,
  deleteReceivingHistoryApi,
  postShippingPlanningApi,
  getAllShippingPlanningApi,
  getShippingPlanningInforApi,
  patchShippingPlanningApi,
  completeShippingPlanningApi,
  cancelShippingPlanningApi,
  deleteShippingPlanningApi,
  getShippingHistoryInforApi,
  getAllShippingHistoryApi,
  deleteShippingHistoryApi,
  getBomInforApi,
  postBomInforApi,
  deleteBomListApi,
  patchBomInforApi,
  postBomItemApi,
  patchBomItemApi,
  deleteBomItemApi,
  postReceivingPlanningItemApi,
  patchReceivingPlanningItemApi,
  deleteReceivingPlanningItemApi,
  postShippingPlanningItemApi,
  deleteShippingPlanningItemApi,
  patchShippingPlanningItemApi,
  postManufacturingPlanningApi,
  getAllManufacturingPlanningApi,
  getManufacturingPlanningInforApi,
  patchManufacturingPlanningBomApi,
  postManufacturingPlanningBomApi,
  deleteManufacturingPlanningItemApi,
  patchManufacturingPlanningApi,
  completeManufacturingPlanningApi,
  cancelManufacturingPlanningApi,
  deleteManufacturingPlanningApi,
  getManufacturingHistoryInforApi,
  getAllManufacturingHistoryApi,
  deleteManufacturingHistoryApi,
} from 'src/api/mes';

class mesStore {
  alertStore;

  shippingPlanningList = [];

  shippingHistoryList = [];

  manufacturingList = [];

  receivingList = [];

  items = [];

  itemType = [];

  itemUnit = [];

  itemCountry = [];

  bomList = [];

  newBomInfor;

  newBomList = [];

  editBomList = [];

  deleteBomList = [];

  deleteBomItemList = [];

  contacts = [];

  startIndex = 0;

  endIndex = 1;

  selectedStartDate = [null, null];

  selectedDueDate = [null, null];

  selectedCompleteDate = [null, null];

  shippingPlanningInfor = {
    id: null,
    code: null,
    planState: null,
    startDate: null,
    dueDate: null,
    totalPrice: null,
    description: null,
    createdAt: null,
    updatedAt: null,
    shippingItemData: [
      {
        id: null,
        quantity: null,
        unitPrice: null,
        createdAt: null,
        updatedAt: null,
        itemData: {
          id: null,
          name: null,
          code: null,
          unit: null,
          itemType: null,
        },
      },
    ],
    workerData: {
      id: null,
      name: null,
      code: null,
    },
    contactData: {
      id: null,
      name: null,
      code: null,
    },
  };

  shippingHistoryInfor = {
    id: null,
    code: null,
    planState: null,
    startDate: null,
    dueDate: null,
    completeDate: null,
    totalPrice: null,
    description: null,
    createdAt: null,
    updatedAt: null,
    shippingItemData: [
      {
        id: null,
        quantity: null,
        unitPrice: null,
        createdAt: null,
        updatedAt: null,
        itemData: {
          id: null,
          name: null,
          code: null,
          unit: null,
          itemType: null,
        },
      },
    ],
    workerData: {
      id: null,
      name: null,
      code: null,
    },
    contactData: {
      id: null,
      name: null,
      code: null,
    },
  };

  manufacturingPlanningInfor = {
    id: '',
    code: '',
    planState: '',
    dueDate: '',
    quantity: '',
    totalPrice: '',
    description: '',
    createdAt: '',
    updatedAt: '',
    itemData: {
      id: '',
      name: '',
      code: '',
      unit: '',
      itemType: '',
    },
    workerData: {
      id: '',
      name: '',
      code: '',
    },
    bomItems: [
      {
        id: '',
        quantity: '',
        cost: '',
        description: '',
        createdAt: '',
        updatedAt: '',
        itemData: {
          id: '',
          name: '',
          code: '',
          cost: '',
          unit: '',
          itemType: '',
        },
      },
      {
        id: '',
        quantity: '',
        cost: '',
        description: '',
        createdAt: '',
        updatedAt: '',
        itemData: {
          id: '',
          name: '',
          code: '',
          cost: '',
          unit: '',
          itemType: '',
        },
      },
    ],
    originalBom: {
      id: '',
      name: '',
      description: '',
    },
  };

  manufacturingHistoryInfor = {
    id: '',
    code: '',
    planState: '',
    dueDate: '',
    completeDate: '',
    quantity: '',
    totalPrice: '',
    description: '',
    createdAt: '',
    updatedAt: '',
    itemData: {
      id: '',
      name: '',
      code: '',
      unit: '',
      itemType: '',
      country: '',
      cost: '',
      sellPrice: '',
      description: '',
      isDeleted: '',
    },
    workerData: {
      id: '',
      name: '',
      code: '',
      email: '',
      isDeleted: '',
    },
    bomData: {
      id: '',
      name: '',
      isDeleted: '',
    },
    bomItems: [
      {
        id: '',
        quantity: '',
        cost: '',
        description: '',
        createdAt: '',
        updatedAt: '',
        itemData: {
          id: '',
          name: '',
          code: '',
          unit: '',
          itemType: '',
          country: '',
          cost: '',
          sellPrice: '',
          description: '',
          isDeleted: '',
        },
      },
    ],
  };

  receivingPlanningInfor = {
    id: null,
    code: null,
    planState: null,
    startDate: null,
    dueDate: null,
    totalPrice: null,
    description: null,
    createdAt: null,
    updatedAt: null,
    receivingItemData: [
      {
        id: null,
        quantity: null,
        unitPrice: null,
        createdAt: null,
        updatedAt: null,
        itemData: {
          id: null,
          name: null,
          code: null,
          unit: null,
          itemType: null,
        },
      },
    ],
    workerData: {
      id: null,
      name: null,
      code: null,
    },
    contactData: {
      id: null,
      name: null,
      code: null,
    },
  };

  receivingHistoryInfor = {
    id: null,
    code: null,
    planState: null,
    startDate: null,
    dueDate: null,
    completeDate: null,
    totalPrice: null,
    description: null,
    createdAt: null,
    updatedAt: null,
    receivingItemData: [
      {
        id: null,
        quantity: null,
        unitPrice: null,
        createdAt: null,
        updatedAt: null,
        itemData: {
          id: null,
          name: null,
          code: null,
          unit: null,
          itemType: null,
        },
      },
    ],
    workerData: {
      id: null,
      name: null,
      code: null,
    },
    contactData: {
      id: null,
      name: null,
      code: null,
    },
  };

  itemInfor = {
    id: null,
    code: null,
    name: null,
    itemType: {
      id: null,
      name: null,
    },
    unit: {
      id: null,
      name: null,
    },
    country: {
      id: null,
      name: null,
    },
    cost: null,
    sellPrice: null,
    description: null,
    image: {
      id: null,
      imageUrl: null,
      filePath: null,
    },
  };

  bomInfor = {
    id: null,
    name: null,
    totalPrice: null,
    description: null,
    createdAt: null,
    updatedAt: null,
    itemData: {
      id: null,
      name: null,
      code: null,
      cost: null,
      unit: null,
      itemType: null,
    },
    bomItems: [
      {
        id: null,
        quantity: null,
        description: null,
        createdAt: null,
        updatedAt: null,
        itemData: {
          id: null,
          name: null,
          code: null,
          cost: null,
          unit: null,
          itemType: null,
        },
      },
    ],
  };

  constructor({ alertStore, utilStore }) {
    makeAutoObservable(this);
    this.alertStore = alertStore;
    this.utilStore = utilStore;
  }

  initItemInfor = async () => {
    this.itemInfor = {
      id: null,
      code: null,
      name: null,
      itemType: {
        id: null,
        name: null,
      },
      unit: {
        id: null,
        name: null,
      },
      country: {
        id: null,
        name: null,
      },
      cost: null,
      sellPrice: null,
      description: null,
      image: {
        id: null,
        imageUrl: null,
        filePath: null,
      },
    };
  };

  setItemInfor = (target, value) => {
    this.itemInfor = { ...this.itemInfor, [target]: value };
  };

  setBomList = (bomList) => {
    this.bomList = bomList;
  };

  setNewBomInfor = (newBomInfor) => {
    this.newBomInfor = newBomInfor;
  };

  setNewBomList = (newBomList) => {
    this.newBomList = newBomList;
  };

  setDeleteBomList = (deleteBomList) => {
    this.deleteBomList = deleteBomList;
  };

  setDeleteBomItemList = (deleteBomItemList) => {
    this.deleteBomItemList = deleteBomItemList;
  };

  setEditBomList = (editBomList) => {
    this.editBomList = editBomList;
  };

  setSelectedStartDate = (date) => {
    this.selectedStartDate = date;
  };

  setSelectedDueDate = (date) => {
    this.selectedDueDate = date;
  };

  setSelectedCompleteDate = (date) => {
    this.selectedCompleteDate = date;
  };

  initShippingPlanningList = async () => {
    this.shippingPlanningList = [];
  };

  initShippingInfor = async () => {
    this.shippingPlanningInfor = {
      id: null,
      code: null,
      planState: null,
      startDate: null,
      dueDate: null,
      totalPrice: null,
      description: null,
      createdAt: null,
      updatedAt: null,
      shippingItemData: [
        {
          id: null,
          quantity: null,
          unitPrice: null,
          createdAt: null,
          updatedAt: null,
          itemData: {
            id: null,
            name: null,
            code: null,
            unit: null,
            itemType: null,
          },
        },
      ],
      workerData: {
        id: null,
        name: null,
        code: null,
      },
      contactData: {
        id: null,
        name: null,
        code: null,
      },
    };
  };

  initShippingHistoryInfor = async () => {
    this.shippingHistoryInfor = {
      id: null,
      code: null,
      planState: null,
      startDate: null,
      dueDate: null,
      completeDate: null,
      totalPrice: null,
      description: null,
      createdAt: null,
      updatedAt: null,
      shippingItemData: [
        {
          id: null,
          quantity: null,
          unitPrice: null,
          createdAt: null,
          updatedAt: null,
          itemData: {
            id: null,
            name: null,
            code: null,
            unit: null,
            itemType: null,
          },
        },
      ],
      workerData: {
        id: null,
        name: null,
        code: null,
      },
      contactData: {
        id: null,
        name: null,
        code: null,
      },
    };
  };

  initManufacturingList = async () => {
    this.manufacturingList = [];
  };

  initManufacturingInfor = async () => {
    this.manufacturingPlanningInfor = {
      id: '',
      code: '',
      planState: '',
      dueDate: '',
      quantity: '',
      totalPrice: '',
      description: '',
      createdAt: '',
      updatedAt: '',
      itemData: {
        id: '',
        name: '',
        code: '',
        unit: '',
        itemType: '',
      },
      workerData: {
        id: '',
        name: '',
        code: '',
      },
      bomItems: [
        {
          id: '',
          quantity: '',
          cost: '',
          description: '',
          createdAt: '',
          updatedAt: '',
          itemData: {
            id: '',
            name: '',
            code: '',
            cost: '',
            unit: '',
            itemType: '',
          },
        },
        {
          id: '',
          quantity: '',
          cost: '',
          description: '',
          createdAt: '',
          updatedAt: '',
          itemData: {
            id: '',
            name: '',
            code: '',
            cost: '',
            unit: '',
            itemType: '',
          },
        },
      ],
      originalBom: {
        id: '',
        name: '',
        description: '',
      },
    };
  };

  initReceivingList = async () => {
    this.receivingList = [];
  };

  initReceivingInfor = async () => {
    this.receivingPlanningInfor = {
      id: null,
      code: null,
      planState: null,
      startDate: null,
      dueDate: null,
      totalPrice: null,
      description: null,
      createdAt: null,
      updatedAt: null,
      receivingItemData: [
        {
          id: null,
          quantity: null,
          unitPrice: null,
          createdAt: null,
          updatedAt: null,
          itemData: {
            id: null,
            name: null,
            code: null,
            unit: null,
            itemType: null,
          },
        },
      ],
      workerData: {
        id: null,
        name: null,
        code: null,
      },
      contactData: {
        id: null,
        name: null,
        code: null,
      },
    };
  };

  initReceivingHistoryInfor = async () => {
    this.receivingHistoryInfor = {
      id: null,
      code: null,
      planState: null,
      startDate: null,
      dueDate: null,
      completeDate: null,
      totalPrice: null,
      description: null,
      createdAt: null,
      updatedAt: null,
      receivingItemData: [
        {
          id: null,
          quantity: null,
          unitPrice: null,
          createdAt: null,
          updatedAt: null,
          itemData: {
            id: null,
            name: null,
            code: null,
            unit: null,
            itemType: null,
          },
        },
      ],
      workerData: {
        id: null,
        name: null,
        code: null,
      },
      contactData: {
        id: null,
        name: null,
        code: null,
      },
    };
  };

  initBomList = async () => {
    this.bomList = [];
  };

  initNewBomList = async () => {
    this.newBomList = [];
  };

  initEditBomList = () => {
    this.editBomList = [];
  };

  initDeleteBomList = () => {
    this.deleteBomList = [];
  };

  initDeleteBomItemList = () => {
    this.deleteBomItemList = [];
  };

  initBomInfor = async () => {
    this.bomInfor = {
      id: null,
      name: null,
      totalPrice: null,
      description: null,
      createdAt: null,
      updatedAt: null,
      itemData: {
        id: null,
        name: null,
        code: null,
        cost: null,
        unit: null,
        itemType: null,
      },
      bomItems: [
        {
          id: null,
          quantity: null,
          description: null,
          createdAt: null,
          updatedAt: null,
          itemData: {
            id: null,
            name: null,
            code: null,
            cost: null,
            unit: null,
            itemType: null,
          },
        },
      ],
    };
  };

  createNewShipping = async (newShipping) => {
    try {
      const res = await postShippingPlanningApi({
        ...newShipping,
        timeZone: this.utilStore.getTimezone(),
      });
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '주문 계획 등록에 성공하였습니다',
      );
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  getAllShippingPlanning = async (getShippingPlanningInfor) => {
    try {
      const res = await getAllShippingPlanningApi({
        ...getShippingPlanningInfor,
        timeZone: this.utilStore.getTimezone(),
      });
      if (res.code) throw res;
      this.shippingPlanningList = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getShippingPlanningInfor = async (getShippingInfo) => {
    try {
      const res = await getShippingPlanningInforApi(getShippingInfo);
      if (res.code) throw res;
      this.shippingPlanningInfor = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  editShippingPlanningAndAddItems = async (shippingId, shippingItemData) => {
    try {
      const addOrEditPlanningItemsRes = shippingItemData.map((eachItemData) => {
        return eachItemData.id
          ? patchShippingPlanningItemApi({
              id: eachItemData.id,
              itemId: eachItemData.itemData.itemId,
              unitPrice: eachItemData.unitPrice,
              quantity: eachItemData.quantity,
            })
          : postShippingPlanningItemApi({
              shippingId: shippingId,
              itemId: eachItemData.itemData.itemId,
              unitPrice: eachItemData.unitPrice,
              quantity: eachItemData.quantity,
            });
      });

      return Promise.all(addOrEditPlanningItemsRes)
        .then((resList) => {
          resList.forEach((eachRes) => {
            if (eachRes.code) throw eachRes;
          });
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  deleteShippingPlanningItemById = async (deleteBomItemList) => {
    try {
      const deleteShippingPlanningItemRes = deleteBomItemList.map(
        (eachDeleteBomItemId) => {
          return deleteShippingPlanningItemApi(eachDeleteBomItemId);
        },
      );
      return Promise.all(deleteShippingPlanningItemRes)
        .then((resList) => {
          resList.forEach((eachRes) => {
            if (eachRes.code) throw eachRes;
          });
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  updateShippingPlanningInfor = async (modifyShippingInfo) => {
    try {
      const res = await patchShippingPlanningApi(modifyShippingInfo);

      if (modifyShippingInfo.shippingItemData.length) {
        const editItemRes = await this.editShippingPlanningAndAddItems(
          modifyShippingInfo.id,
          modifyShippingInfo.shippingItemData,
        );
        if (editItemRes !== true) throw editItemRes;
      }

      if (modifyShippingInfo.deleteItemList.length) {
        const dleteBomItemRes = await this.deleteShippingPlanningItemById(
          modifyShippingInfo.deleteItemList,
        );
        if (dleteBomItemRes !== true) throw dleteBomItemRes;
      }

      if (modifyShippingInfo.isComplete) {
        const completeRes = await completeShippingPlanningApi({
          ...modifyShippingInfo,
          timeZone: this.utilStore.getTimezone(),
        });
        if (completeRes !== 'OK') throw completeRes;
      } else if (modifyShippingInfo.isCanceled) {
        const cancelRes = await cancelShippingPlanningApi({
          ...modifyShippingInfo,
          timeZone: this.utilStore.getTimezone(),
        });
        if (cancelRes !== 'OK') throw cancelRes;
      }
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '주문 계획 수정에 성공하였습니다',
      );
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  deleteShippingPlanningById = async (selectedShippingList) => {
    try {
      const deleteItemTasks = selectedShippingList.map((itemInfo) => {
        return deleteShippingPlanningApi(itemInfo.id);
      });
      return Promise.all(deleteItemTasks)
        .then(() => {
          this.getAllShippingPlanning();
          this.alertStore.setAlertOpen(
            'success',
            `주문 계획 삭제를 완료했습니다.`,
          );
          return true;
        })
        .catch((error) => {
          this.alertStore.setAlertOpen('error', error);
          return false;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  getShippingHistoryInfor = async (getShippingInfo) => {
    try {
      const res = await getShippingHistoryInforApi(getShippingInfo);
      if (res.code) throw res;
      this.shippingHistoryInfor = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getAllShippingHistory = async (getShippingHistory) => {
    try {
      const res = await getAllShippingHistoryApi({
        ...getShippingHistory,
        timeZone: this.utilStore.getTimezone(),
      });
      if (res.code) throw res;
      this.shippingHistoryList = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  deleteShippingHistoryById = async (
    selectedShippingList,
    onlyHistory = false,
  ) => {
    try {
      const deleteItemTasks = selectedShippingList.map((itemInfo) => {
        return deleteShippingHistoryApi(itemInfo.id);
      });
      return Promise.all(deleteItemTasks)
        .then(() => {
          this.getAllShippingHistory();
          this.alertStore.setAlertOpen(
            'success',
            `${onlyHistory ? '출고' : '주문'} 이력 삭제를 완료했습니다.`,
          );
          return true;
        })
        .catch((error) => {
          this.alertStore.setAlertOpen('error', error);
          return false;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  createNewManufacturing = async (newManufacturing) => {
    try {
      const res = await postManufacturingPlanningApi({
        ...newManufacturing,
        timeZone: this.utilStore.getTimezone(),
      });
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '생산 계획 등록에 성공하였습니다',
      );
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  getAllManufacturingPlanning = async (getManufacturingPlanningInfor) => {
    try {
      const res = await getAllManufacturingPlanningApi({
        ...getManufacturingPlanningInfor,
        timeZone: this.utilStore.getTimezone(),
      });
      if (res.code) throw res;
      this.manufacturingList = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getManufacturingPlanningInfor = async (getManufacturingInfo) => {
    try {
      const res = await getManufacturingPlanningInforApi(getManufacturingInfo);
      if (res.code) throw res;
      this.manufacturingPlanningInfor = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  editManufacturingPlanningAndAddBom = async (manufacturingId, bomData) => {
    try {
      const addOrEditPlanningItemsRes = bomData.map((eachBomData) => {
        return eachBomData.id
          ? patchManufacturingPlanningBomApi({
              id: eachBomData.id,
              itemId: eachBomData.itemData.id,
              cost: eachBomData.cost,
              quantity: eachBomData.quantity,
              description: eachBomData.description,
            })
          : postManufacturingPlanningBomApi({
              manufacturingId: manufacturingId,
              itemId: eachBomData.itemData.id,
              cost: eachBomData.cost,
              quantity: eachBomData.quantity,
              description: eachBomData.description,
            });
      });

      return Promise.all(addOrEditPlanningItemsRes)
        .then((resList) => {
          resList.forEach((eachRes) => {
            if (eachRes.code) throw eachRes;
          });
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  deleteManufacturingPlanningBomById = async (deleteBomList) => {
    try {
      const deleteManufacturingPlanningBomRes = deleteBomList.map(
        (eachDeleteBomId) => {
          return deleteManufacturingPlanningItemApi(eachDeleteBomId);
        },
      );
      return Promise.all(deleteManufacturingPlanningBomRes)
        .then((resList) => {
          resList.forEach((eachRes) => {
            if (eachRes.code) throw eachRes;
          });
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  updateManufacturingPlanningInfor = async (modifyManufacturingInfo) => {
    try {
      const res = await patchManufacturingPlanningApi(modifyManufacturingInfo);

      if (modifyManufacturingInfo.bomData.length) {
        const editBomRes = await this.editManufacturingPlanningAndAddBom(
          modifyManufacturingInfo.id,
          modifyManufacturingInfo.bomData,
        );
        if (editBomRes !== true) throw editBomRes;
      }

      if (modifyManufacturingInfo.deleteBomList.length) {
        const dleteBomItemRes = await this.deleteManufacturingPlanningBomById(
          modifyManufacturingInfo.deleteBomList,
        );
        if (dleteBomItemRes !== true) throw dleteBomItemRes;
      }

      if (modifyManufacturingInfo.isComplete) {
        const completeRes = await completeManufacturingPlanningApi({
          ...modifyManufacturingInfo,
          timeZone: this.utilStore.getTimezone(),
        });
        if (completeRes !== 'OK') throw completeRes;
      } else if (modifyManufacturingInfo.isCanceled) {
        const cancelRes = await cancelManufacturingPlanningApi({
          ...modifyManufacturingInfo,
          timeZone: this.utilStore.getTimezone(),
        });
        if (cancelRes !== 'OK') throw cancelRes;
      }
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '생산 계획 수정에 성공하였습니다',
      );
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  deleteManufacturingPlanningById = async (selectedManufacturingList) => {
    try {
      const deleteItemTasks = selectedManufacturingList.map((itemInfo) => {
        return deleteManufacturingPlanningApi(itemInfo.id);
      });
      return Promise.all(deleteItemTasks)
        .then(() => {
          this.getAllManufacturingPlanning();
          this.alertStore.setAlertOpen(
            'success',
            '생산 계획 삭제를 완료했습니다.',
          );
          return true;
        })
        .catch((error) => {
          this.alertStore.setAlertOpen('error', error);
          return false;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  getManufacturingHistoryInfor = async (getManufacturingInfo) => {
    try {
      const res = await getManufacturingHistoryInforApi(getManufacturingInfo);
      if (res.code) throw res;
      this.manufacturingHistoryInfor = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getAllManufacturingHistory = async (getManufacturingHistory) => {
    try {
      const res = await getAllManufacturingHistoryApi({
        ...getManufacturingHistory,
        timeZone: this.utilStore.getTimezone(),
      });
      if (res.code) throw res;
      this.manufacturingList = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  deleteManufacturingHistoryById = async (selectedManufacturingList) => {
    try {
      const deleteItemTasks = selectedManufacturingList.map((itemInfo) => {
        return deleteManufacturingHistoryApi(itemInfo.id);
      });
      return Promise.all(deleteItemTasks)
        .then(() => {
          this.getAllManufacturingHistory();
          this.alertStore.setAlertOpen(
            'success',
            '생산 이력 삭제를 완료했습니다.',
          );
          return true;
        })
        .catch((error) => {
          this.alertStore.setAlertOpen('error', error);
          return false;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  createNewReceiving = async (newReceiving) => {
    try {
      const res = await postReceivingPlanningApi({
        ...newReceiving,
        timeZone: this.utilStore.getTimezone(),
      });
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '입고 계획 등록에 성공하였습니다',
      );
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  getAllReceivingPlanning = async (getReceivingPlanningInfor) => {
    try {
      const res = await getAllReceivingPlanningApi({
        ...getReceivingPlanningInfor,
        timeZone: this.utilStore.getTimezone(),
      });
      if (res.code) throw res;
      this.receivingList = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getReceivingPlanningInfor = async (getReceivingInfo) => {
    try {
      const res = await getReceivingPlanningInforApi(getReceivingInfo);
      if (res.code) throw res;
      this.receivingPlanningInfor = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  editReceivingPlanningAndAddItems = async (receivingId, receivingItemData) => {
    try {
      const addOrEditPlanningItemsRes = receivingItemData.map(
        (eachItemData) => {
          return eachItemData.id
            ? patchReceivingPlanningItemApi({
                id: eachItemData.id,
                itemId: eachItemData.itemData.itemId,
                unitPrice: eachItemData.unitPrice,
                quantity: eachItemData.quantity,
              })
            : postReceivingPlanningItemApi({
                receivingId: receivingId,
                itemId: eachItemData.itemData.itemId,
                unitPrice: eachItemData.unitPrice,
                quantity: eachItemData.quantity,
              });
        },
      );

      return Promise.all(addOrEditPlanningItemsRes)
        .then((resList) => {
          resList.forEach((eachRes) => {
            if (eachRes.code) throw eachRes;
          });
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  deleteReceivingPlanningItemById = async (deleteBomItemList) => {
    try {
      const deleteReceivingPlanningItemRes = deleteBomItemList.map(
        (eachDeleteBomItemId) => {
          return deleteReceivingPlanningItemApi(eachDeleteBomItemId);
        },
      );
      return Promise.all(deleteReceivingPlanningItemRes)
        .then((resList) => {
          resList.forEach((eachRes) => {
            if (eachRes.code) throw eachRes;
          });
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  updateReceivingPlanningInfor = async (modifyReceivingInfo) => {
    try {
      const res = await patchReceivingPlanningApi(modifyReceivingInfo);
      if (res.code) throw res;

      if (modifyReceivingInfo.receivingItemData.length) {
        const editItemRes = await this.editReceivingPlanningAndAddItems(
          modifyReceivingInfo.id,
          modifyReceivingInfo.receivingItemData,
        );
        if (editItemRes !== true) throw editItemRes;
      }

      if (modifyReceivingInfo.deleteItemList.length) {
        const dleteBomItemRes = await this.deleteReceivingPlanningItemById(
          modifyReceivingInfo.deleteItemList,
        );
        if (dleteBomItemRes !== true) throw dleteBomItemRes;
      }

      if (modifyReceivingInfo.isComplete) {
        const completeRes = await completeReceivingPlanningApi({
          ...modifyReceivingInfo,
          timeZone: this.utilStore.getTimezone(),
        });
        if (completeRes !== 'OK') throw completeRes;
      } else if (modifyReceivingInfo.isCanceled) {
        const cancelRes = await cancelReceivingPlanningApi({
          ...modifyReceivingInfo,
          timeZone: this.utilStore.getTimezone(),
        });
        if (cancelRes !== 'OK') throw cancelRes;
      }

      this.alertStore.setAlertOpen(
        'success',
        '입고 계획 수정에 성공하였습니다',
      );
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  deleteReceivingPlanningById = async (selectedReceivingList) => {
    try {
      const deleteItemTasks = selectedReceivingList.map((itemInfo) => {
        return deleteReceivingPlanningApi(itemInfo.id);
      });
      return Promise.all(deleteItemTasks)
        .then(() => {
          this.getAllReceivingPlanning();
          this.alertStore.setAlertOpen(
            'success',
            `입고 계획 삭제를 완료했습니다.`,
          );
          return true;
        })
        .catch((error) => {
          this.alertStore.setAlertOpen('error', error);
          return false;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  getReceivingHistoryInfor = async (getReceivingInfo) => {
    try {
      const res = await getReceivingHistoryInforApi(getReceivingInfo);
      if (res.code) throw res;
      this.receivingHistoryInfor = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getAllReceivingHistory = async (getReceivingHistory) => {
    try {
      const res = await getAllReceivingHistoryApi({
        ...getReceivingHistory,
        timeZone: this.utilStore.getTimezone(),
      });
      if (res.code) throw res;
      this.receivingList = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  deleteReceivingHistoryById = async (selectedReceivingList) => {
    try {
      const deleteItemTasks = selectedReceivingList.map((itemInfo) => {
        return deleteReceivingHistoryApi(itemInfo.id);
      });
      return Promise.all(deleteItemTasks)
        .then(() => {
          this.getAllReceivingHistory();
          this.alertStore.setAlertOpen(
            'success',
            `입고 이력 삭제를 완료했습니다.`,
          );
          return true;
        })
        .catch((error) => {
          this.alertStore.setAlertOpen('error', error);
          return false;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  createNewItem = async (newItemInfo) => {
    try {
      const res = await postItemApi(newItemInfo);
      if (res.code) throw res;

      if (newItemInfo.newBomList.length) {
        const newBomRes = await this.createNewBomList(
          newItemInfo.newBomList.map((eachNewBomData) => {
            return {
              ...eachNewBomData,
              itemId: res.data.id,
            };
          }),
        );
        if (newBomRes !== true) throw newBomRes;
      }

      this.alertStore.setAlertOpen('success', '품목 추가에 성공하였습니다');
      return res.data.id;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  getAllItems = async () => {
    try {
      const res = await getAllItemsApi();
      if (res.code) throw res;
      this.items = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getItemInfor = async (getItemInfo) => {
    try {
      const res = await getItemInforApi(getItemInfo);
      if (res.code) throw res;
      this.itemInfor = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  deleteItemImageById = async (selectedItemImages) => {
    try {
      const deleteItemImages = selectedItemImages.map((imageInfo) => {
        return deleteImageApi(imageInfo.id);
      });
      return Promise.all(deleteItemImages)
        .then((resList) => {
          resList.forEach((eachRes) => {
            if (eachRes.code) throw eachRes;
          });
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  deleteBomListById = async (deleteBomList) => {
    try {
      const deleteBomRes = deleteBomList.map((eachDeleteBomId) => {
        return deleteBomListApi(eachDeleteBomId);
      });
      return Promise.all(deleteBomRes)
        .then((resList) => {
          resList.forEach((eachRes) => {
            if (eachRes.code) throw eachRes;
          });
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  createNewBomList = async (newBomList) => {
    try {
      const createNewBomRes = [];

      // eslint-disable-next-line no-restricted-syntax
      for await (let eachBomInfo of newBomList) {
        await postBomInforApi(eachBomInfo)
          .then((res) => {
            if (typeof res === 'number') createNewBomRes.push(true);
            else createNewBomRes.push(false);
          })
          .catch((error) => {
            this.alertStore.setAlertOpen('error', error);
            createNewBomRes.push(false);
          });
      }

      return Promise.all(createNewBomRes)
        .then((resList) => {
          resList.forEach((eachRes) => {
            if (eachRes.code) throw eachRes;
          });
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  editBomListAndAddItems = async (editBomList) => {
    try {
      const editBomDefaultInforRes = editBomList.map((eachBomInfo) => {
        return patchBomInforApi({
          id: eachBomInfo.id,
          name: eachBomInfo.name,
          totalPrice: eachBomInfo.totalPrice,
          description: eachBomInfo.description,
        });
      });

      const addOrEditBomItemsRes = editBomList.map((eachBomInfo) => {
        return eachBomInfo.bomItemData.map((eachBomItem) => {
          if (eachBomItem.idInBomItemData) {
            return patchBomItemApi({
              id: eachBomItem.idInBomItemData,
              itemId: eachBomItem.itemId,
              cost: eachBomItem.cost,
              quantity: eachBomItem.quantity,
              description: eachBomItem.description,
            });
          }
          return postBomItemApi({
            itemId: eachBomItem.itemId,
            bomId: eachBomInfo.id,
            quantity: eachBomItem.quantity,
            description: eachBomItem.description,
            cost: eachBomItem.cost,
          });
        });
      });

      return Promise.all(editBomDefaultInforRes, addOrEditBomItemsRes)
        .then((resList) => {
          resList.forEach((eachRes) => {
            if (eachRes.code) throw eachRes;
          });
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  deleteBomItemById = async (deleteBomItemList) => {
    try {
      const deleteBomItemRes = deleteBomItemList.map((eachDeleteBomItemId) => {
        return deleteBomItemApi(eachDeleteBomItemId);
      });
      return Promise.all(deleteBomItemRes)
        .then((resList) => {
          resList.forEach((eachRes) => {
            if (eachRes.code) throw eachRes;
          });
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  updateItemInfor = async (modifyItemInfo) => {
    try {
      if (modifyItemInfo.isEditImage && modifyItemInfo.registeredImageId) {
        const imageDeleteRes = await this.deleteItemImageById([
          { id: modifyItemInfo.registeredImageId },
        ]);
        if (imageDeleteRes !== true) throw imageDeleteRes;
      }

      if (modifyItemInfo.deleteBomList.length) {
        const deleteBomRes = await this.deleteBomListById(
          modifyItemInfo.deleteBomList,
        );
        if (deleteBomRes !== true) throw deleteBomRes;
      }

      if (modifyItemInfo.newBomList.length) {
        const newBomRes = await this.createNewBomList(
          modifyItemInfo.newBomList,
        );
        if (newBomRes !== true) throw newBomRes;
      }

      if (modifyItemInfo.editBomList.length) {
        const editBomRes = await this.editBomListAndAddItems(
          modifyItemInfo.editBomList,
        );
        if (editBomRes !== true) throw editBomRes;
      }

      if (modifyItemInfo.deleteBomItemList.length) {
        const dleteBomItemRes = await this.deleteBomItemById(
          modifyItemInfo.deleteBomItemList,
        );
        if (dleteBomItemRes !== true) throw dleteBomItemRes;
      }

      const res = await patchItemApi(modifyItemInfo);
      if (res.code) throw res;
      this.alertStore.setAlertOpen('success', '품목 수정에 성공하였습니다');
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  deleteItemsById = async (selecteditmes) => {
    try {
      const deleteItemTasks = selecteditmes.map((itemInfo) => {
        return deleteItemApi(itemInfo.id);
      });
      return Promise.all(deleteItemTasks)
        .then(() => {
          this.getAllItems();
          this.alertStore.setAlertOpen('success', `품목 삭제를 완료했습니다.`);
          return true;
        })
        .catch((error) => {
          this.alertStore.setAlertOpen('error', error);
          return false;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  createNewItemType = async (newItemInfo) => {
    try {
      const res = await postItemTypeApi(newItemInfo);
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '품목 종류 추가에 성공하였습니다',
      );
      this.getItemType();
      return res.data;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getItemType = async () => {
    try {
      const res = await getItemTypeApi();
      if (res.code) throw res;
      this.itemType = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  updateItemType = async (modifyItemType) => {
    try {
      const res = await patchItemApi(modifyItemType);
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  deleteItemType = async (deleteItemTypeId) => {
    try {
      const res = await deleteItemTypeApi(deleteItemTypeId);
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '품목 종류 삭제에 성공하였습니다',
      );
      this.getItemType();
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  createNewItemUnit = async (newItemInfo) => {
    try {
      const res = await postItemUnitApi(newItemInfo);
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '품목 단위 추가에 성공하였습니다',
      );
      this.getItemUnit();
      return res.data;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getItemUnit = async () => {
    try {
      const res = await getItemUnitApi();
      if (res.code) throw res;
      this.itemUnit = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  updateItemUnit = async (getItemInfo) => {
    try {
      const res = await patchItemApi(getItemInfo);
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  deleteItemUnit = async (deleteItemUnitId) => {
    try {
      const res = await deleteItemUnitApi(deleteItemUnitId);
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '품목 단위 삭제에 성공하였습니다',
      );
      this.getItemUnit();
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  createNewItemCountry = async (newItemInfo) => {
    try {
      const res = await postItemCountryApi(newItemInfo);
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '품목 단위 추가에 성공하였습니다',
      );
      this.getItemCountry();
      return res.data;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getItemCountry = async () => {
    try {
      const res = await getItemCountryApi();
      if (res.code) throw res;
      this.itemCountry = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  updateItemCountry = async (getItemInfo) => {
    try {
      const res = await patchItemApi(getItemInfo);
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  deleteItemCountry = async (deleteItemCountryId) => {
    try {
      const res = await deleteItemCountryApi(deleteItemCountryId);
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '품목 단위 삭제에 성공하였습니다',
      );
      this.getItemCountry();
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getBomList = async (getBomList) => {
    try {
      const res = await getBomInforApi(getBomList);
      if (res.code) throw res;
      this.bomList = res.data;
      return res.data;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getBomInfor = async (getBomInfo) => {
    try {
      const res = await getBomInforApi(getBomInfo);
      if (res.code) throw res;
      this.bomInfor = res.data;
      return res.data;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getAllContacts = async () => {
    try {
      const res = await getContactApi();
      if (res.code) throw res;
      this.contacts = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };
}

export default mesStore;
