import moment from 'moment';
import 'moment/min/locales';
import i18next from 'i18next';
import { getStore } from 'src/mobx-store';

export const convertTimestampToString = (date, options = {}) => {
  const { accountStore } = getStore();
  const { account } = accountStore;
  const { timeOnly = false, dateOnly = false, fixUTC = false } = options;

  if (date) {
    const dateObject = new Date(date);
    const year = fixUTC
      ? dateObject.getUTCFullYear()
      : dateObject.getFullYear();
    const month = (
      fixUTC ? dateObject.getUTCMonth() : dateObject.getMonth() + 1
    )
      .toString()
      .padStart(2, '0');
    const day = (fixUTC ? dateObject.getUTCDate() : dateObject.getDate())
      .toString()
      .padStart(2, '0');
    const hours = (fixUTC ? dateObject.getUTCHours() : dateObject.getHours())
      .toString()
      .padStart(2, '0');
    const minutes = (
      fixUTC ? dateObject.getUTCMinutes() : dateObject.getMinutes()
    )
      .toString()
      .padStart(2, '0');

    const accountDateFormat = account.dateFormat.string.split(
      account.dateFormat.delimiter,
    );
    let dateFormatResult = '';
    accountDateFormat.forEach((eachFormatData, index) => {
      switch (eachFormatData) {
        case 'YYYY':
          dateFormatResult = dateFormatResult.concat(
            year,
            index === accountDateFormat.length - 1
              ? ''
              : account.dateFormat.delimiter,
          );
          break;

        case 'MM':
          dateFormatResult = dateFormatResult.concat(
            month,
            index === accountDateFormat.length - 1
              ? ''
              : account.dateFormat.delimiter,
          );

          break;
        case 'DD':
          dateFormatResult = dateFormatResult.concat(
            day,
            index === accountDateFormat.length - 1
              ? ''
              : account.dateFormat.delimiter,
          );
          break;

        default:
          break;
      }
    });

    if (timeOnly) {
      return `${hours}:${minutes}`;
    }

    if (dateOnly) {
      return dateFormatResult;
    }
    return `${dateFormatResult} ${hours}:${minutes}`;
  }

  return '';
};

export const convertTimeFromSeconds = (time, t) => {
  if (time) {
    const days = Math.floor(time / (60 * 24));
    const remainingValue = time % (60 * 24);
    const hours = Math.floor(remainingValue / 60);
    const minutes = remainingValue % 60;

    let timeString = '';

    if (days > 0) {
      timeString += `${days} ${t('단위_일')} `;
    }

    if (hours > 0) {
      timeString += `${hours} ${t('단위_시간')} `;
    }

    if (minutes > 0) {
      timeString += `${minutes} ${t('단위_분')}`;
    }

    return timeString.trim();
  }
  return null;
};

export const getTodayTimeStamp = () => {
  let date = new Date();
  let todayTimeStamp = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
  );

  return Math.floor(todayTimeStamp / 1000);
};

export const convertDateObjectToTimestamp = (dateString, options = {}) => {
  const { fixUTC = false } = options;
  let date;
  if (fixUTC) {
    const tempDate = new Date(dateString);
    date = new Date(
      Date.UTC(
        tempDate.getFullYear(),
        tempDate.getMonth(),
        tempDate.getDate(),
        tempDate.getHours(),
        tempDate.getMinutes(),
      ),
    );
  } else {
    date = new Date(dateString);
  }
  return Math.floor(date.getTime() / 1000);
};

export const convertUTCToLocalTime = (utcTimestamp) => {
  const utcDate = new Date(utcTimestamp * 1000);

  const localDate = new Date(
    utcDate.getUTCFullYear(),
    utcDate.getUTCMonth(),
    utcDate.getUTCDate(),
    utcDate.getUTCHours(),
    utcDate.getUTCMinutes(),
    utcDate.getUTCSeconds(),
  );

  return localDate;
};

export const formatToAllFromTimestamp = (
  timestamp,
  unit = 's',
  hourFormat = '12',
  dateOnly = false,
) => {
  const { accountStore } = getStore();
  const { account } = accountStore;

  if (timestamp) {
    const time = unit === 's' ? timestamp * 1000 : timestamp;
    return moment(time)
      .locale(i18next.language)
      .tz(account.timeZone)
      .format(
        dateOnly
          ? account.dateFormat.string || 'YYYY-MM-DD'
          : i18next.t(`날짜 및 시간 표시 ${hourFormat}시계`, {
              dateFormatString:
                account.dateFormat.string || 'YYYY-MM-DD HH:mm:ss',
            }),
      );
  }
  return i18next.t('데이터 없음');
};

export const formatToTimeFromTimestamp = (timestamp, t) => {
  if (timestamp) {
    return moment(timestamp * 1000).format('hh:mm A');
  }
  return t('데이터 없음');
};

// 월과 일만 표현되도록 변환
export const convertToMonthDayFormat = (momentFormat) => {
  // 년도 부분 (YYYY, YY)와 그 뒤의 구분자를 제거
  const withoutYear = momentFormat.replace(/(YYYY|YY)[^a-zA-Z]?/g, '').trim();

  // 남은 형식의 끝에 구분자가 있다면 제거
  return withoutYear.replace(/[^a-zA-Z0-9]$/, '');
};

export const getDatePickerDateFormat = () => {
  const { accountStore } = getStore();
  const { account } = accountStore;

  return convertMomentToDateFns(
    convertToMonthDayFormat(account.dateFormat.string),
  );
};

export const convertMomentToDateFns = (momentFormat) => {
  // Moment.js -> date-fns 매핑 테이블
  const formatMap = {
    YYYY: 'yyyy',
    YY: 'yy',
    M: 'L', // 한 자리 또는 두 자리로 표시되는 월
    MM: 'MM', // 두 자리로 표시되는 월
    MMM: 'LLL', // 세 글자의 월 이름
    MMMM: 'LLLL', // 전체 월 이름
    D: 'd',
    DD: 'dd',
    d: 'e',
    ddd: 'eee',
    dddd: 'eeee',
    A: 'aa',
    a: 'aaa',
    H: 'H',
    HH: 'HH',
    h: 'h',
    hh: 'hh',
    m: 'm',
    mm: 'mm',
    s: 's',
    ss: 'ss',
  };

  // 매핑 테이블을 사용하여 Moment 형식을 date-fns 형식으로 변환
  return momentFormat.replace(
    /YYYY|YY|M{1,4}|D{1,2}|d{1,4}|A|a|H{1,2}|h{1,2}|m{1,2}|s{1,2}/g,
    (match) => formatMap[match] || match,
  );
};

export const convertDateFnsToMoment = (dateFnsFormat) => {
  const formatMap = {
    // date-fns to moment mapping
    yyyy: 'YYYY', // 연도
    yy: 'YY', // 2자리 연도
    MM: 'MM', // 월 (01-12)
    M: 'M', // 월 (1-12)
    dd: 'DD', // 일 (01-31)
    d: 'D', // 일 (1-31)
    EEEE: 'dddd', // 요일 (전체 이름)
    EEE: 'ddd', // 요일 (약어)
    HH: 'HH', // 24시간 형식 시 (00-23)
    hh: 'hh', // 12시간 형식 시 (01-12)
    mm: 'mm', // 분 (00-59)
    ss: 'ss', // 초 (00-59)
    a: 'A', // 오전/오후
    Z: 'Z', // 타임존
  };

  return dateFnsFormat.replace(
    /yyyy|yy|MM|M|dd|d|EEEE|EEE|HH|hh|mm|ss|a|Z/g,
    (matched) => formatMap[matched],
  );
};
